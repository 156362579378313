/*     320px — 480px : les mobiles
    481px — 768px : iPads, tablettes
    769px — 1024px : petits écrans, ordinateurs portables
    1025px — 1200px : ordinateurs de bureau, écrans larges
    1201px et plus —  écrans extra larges, TV
     */

.installer-header-container__clz {
    background-color: rgb(26, 21, 21);
}
.installer-container__clz {
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 50px;
    row-gap: 21px;
    display: flex;
}
.installer-main-content__clz {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    column-gap: 50px;
    row-gap: 21px;
    display: flex;
    left: 165px;
    top: 300px;
    position: absolute;
    z-index: 4;
}
.invalid-icon__clz {
    padding-right: 5px;
}

.valid-icon__clz {
    cursor: pointer;
    padding-right: 5px;
}

.container__clz {
    display: flex;
    justify-content: unset;
}

.installer-cards {
    padding-bottom: 100px;
    padding-left: 26px;
    padding-right: 27px;
}

.installer-card-header__clz img {
    display: block;
    border: 0;
    width: 100%;
    height: 259px;
}

.installer-global-title__clz {
    text-align: center;
    padding-top: 22px;
    padding-right: 27%;
    padding-left: 28%;
    padding-bottom: 40px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .installer-global-title__clz {
        text-align: center;
        padding-top: 22px;
        padding-right: 8%;
        padding-left: 6%;
        padding-bottom: 40px;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .installer-global-title__clz {
        text-align: center;
        padding-top: 22px;
        padding-right: 4%;
        padding-left: 4%;
        padding-bottom: 40px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .installer-global-title__clz {
        text-align: center;
        padding-top: 22px;
        padding-right: 4%;
        padding-left: 2%;
        padding-bottom: 40px;
    }
}

.installer-firstIcon:hover {
    transform: scale(1.5);
    transition: all 0.2s ease-in-out;
}
.installer-etk-title__clz {
    text-align: center;
    padding-top: 22px;
    font-size: 55px;
    color: #2f2b2b;
    font-weight: bold;
    font-family: Bebas Neue !important;
}
.installer-opacity-title__clz {
    opacity: 0.7;
    font-style: normal;
    font-display: swap;
    font-weight: bold;
    font-size: 45px;
    font-family: Bebas Neue !important;
    font-size: 55px;
}
.installer-title__clz {
    color: #2f2b2b;
    text-transform: uppercase;
    font-size: 65px;
    line-height: 65px;
    font-weight: bold;
    font-family: "Bebas Neue";
    letter-spacing: 5px;
}

.installer-main-outer_clz {
    position: relative;
    background: rgb(0, 0, 0);
    height: 995px;
    width: 1920px;
    overflow: hidden;
    display: flex;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.installer-outer_clz {
    position: relative;
    background: rgb(0, 0, 0);
    height: 828px;
    width: 1739px;
    overflow: hidden;
    display: flex;
    border-radius: 23px;
}

.installer-main-image__clz .img {
    width: 949px;
    height: 995px;
    right: 0px;
    z-index: 2;
    opacity: 1;
}
@media screen and (min-width: 811px) and (max-width: 1200px) {
    .installer-main-image__clz .img {
        width: 937px;
        height: 995px;
        z-index: 2;
        opacity: 1;
        position: absolute;
        top: 0px;
        right: 0px;
    }
    .installer-p-main-title__clz {
        width: 500px;
        height: 92px;
        text-align: left;
        font: normal normal normal 27px/33px Helvetica Neue;
        letter-spacing: 0px;
        color: #ffffff;
        z-index: 3;
        padding-bottom: 127px;
    }
    .installer-main-outer_clz {
        position: relative;
        background: rgb(0, 0, 0);
        height: 995px;
        overflow: hidden;
        display: flex;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 1191px;
    }
}

@media screen and (min-width: 0px) and (max-width: 810px) {
    .installer-p-title__clz {
        width: 600px !important;
        margin-bottom: 27px;
    }
    .installer-h1-title__clz {
        width: 500px;
        margin-bottom: 2rem !important;
    }
    .installer-second-title__clz {
        padding-bottom: 20px !important;
        width: 528px;
    }
    .installer-main-image__clz .img {
        width: 663px;
        height: 995px;
        z-index: 2;
        opacity: 1;
        position: absolute;
        top: 0px;
        right: 0px;
    }
    .installer-p-main-title__clz {
        width: 500px;
        height: 92px;
        text-align: left;
        font: normal normal normal 27px/33px Helvetica Neue;
        letter-spacing: 0px;
        color: #ffffff;
        z-index: 3;
        padding-bottom: 127px;
    }
    .installer-main-outer_clz {
        position: relative;
        background: rgb(0, 0, 0);
        height: 995px;
        overflow: hidden;
        display: flex;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 811px;
    }
}

@media screen and (min-width: 1201px) {
    .installer-p-main-title__clz {
        color: #ffffff;
        width: 863px;
        height: 92px;
        text-align: left;
        font: normal normal normal 27px/33px Helvetica Neue;
        letter-spacing: 0px;
        color: #ffffff;
        z-index: 3;
    }
}

.installer-image__clz .img {
    position: absolute;
    top: 1px;
    right: 0px;
    z-index: 0;
    animation-delay: 0.5s;
    width: 850px;
    height: 827px;
    background-image: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.6),
        rgba(0, 0, 0, 0.5)
    );
}

@media screen and (min-width: 0px) and (max-width: 1024px) {
    .installer-image__clz .img {
        position: absolute;
        top: 1px;
        right: 0px;
        z-index: 0;
        animation-delay: 0.5s;
        opacity: 0.5;
    }
}

@media screen and (min-width: 0px) and (max-width: 576px) {
    .installer-image__clz .img {
        display: none;
    }
}

@media screen and (min-width: 0px) and (max-width: 576px) {
    .installer-divider {
        display: none;
    }
}

.installer-divider {
    padding: 5px;
}

.installer-content__clz {
    animation-delay: 0.3s;
    position: absolute;
    left: 20px;
    z-index: 3;
    padding-top: 66px;
    padding-left: 49px;
}

.installer-main-title__clz {
    width: 496px;
    height: 138px;
    text-align: left;
    font: normal normal bold 57px/70px Helvetica Neue;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
}

.installer-download__clz {
    width: 490px;
    height: 81px;
    flex-direction: row;
    display: flex;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
}
.installer-download-android__clz {
    width: 490px;
    height: 81px;
    flex-direction: row;
    display: flex;
    display: flex;
    flex-wrap: wrap;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
}
.installer-download__clz:hover {
    box-shadow: 5px 8px 24px 5px rgba(255, 255, 255, 0.8);
    -webkit-box-shadow: 5px 8px 24px 5px rgba(255, 255, 255, 0.8);
    transform: scale(1.1);
}
.installer-h1-title__clz {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: Bebas Neue !important;
    margin-bottom: 4rem;
    text-align: left;
    font: normal normal bold 52px/64px Helvetica Neue;
    letter-spacing: 0px;
    color: #ffffff;
}

.installer-p-title__clz {
    width: 856px;
    height: 110px;

    text-align: left;
    font: normal normal normal 27px/33px Helvetica Neue;
    letter-spacing: 0px;
    color: #ffffff;
}

.main-container__clz {
    display: inline-flex;
    align-items: center;
}

.rounded-point__clz {
    width: 10px;
    height: 10px;
    background: #fff -107% -38% no-repeat padding-box;
    margin-right: 5px;
    border-radius: 100px;
}

.text__clz {
    text-align: start;
    font: normal normal normal 22px/40px Helvetica Neue;
    letter-spacing: 0px;
    color: #ffffff;
}

@media screen and (min-width: 0px) and (max-width: 800px) {
    .text__clz {
        text-align: start;
        font: normal normal normal 20px/35px Helvetica Neue !important;
        letter-spacing: 0px;
        color: #ffffff;
    }
}
.installer-second-title__clz {
    padding-top: 0px;
    padding-bottom: 50px;
    text-align: left;
    font: normal normal bold 27px/33px Helvetica Neue;
    letter-spacing: 0px;
    color: #ffffff;
}

.installer-download-title__clz {
    color: var(--unnamed-color-000000);
    text-align: left;
    font: normal normal normal 25px/30px Helvetica Neue;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-left: 10px;
    padding-top: 7px;
}
.installer-footer-title__clz {
    color: #fff;
    font-size: 40px;
}
.installer-cart-btn_clz {
    color: rgb(234, 228, 228);
    text-align: start;
    font-size: 20px;
    padding-top: 10px;
    padding: 5px;
    text-decoration-line: underline;
    font-family: segoe ui;
}

@media screen and (min-width: 0px) and (max-width: 576px) {
    .installer-cart-btn_clz {
        font-size: 20px;
        color: rgb(234, 228, 228);
        text-align: start;
        padding-top: 10px;
        padding-left: 16px;
        text-decoration-line: underline;
    }
}

.dropdown-menu {
    min-width: 5rem !important;
}
