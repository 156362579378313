@tailwind base;
@tailwind components;
@tailwind utilities;

.dropdown-menu {
    min-width: 5rem !important;
}

.language-switch {
    top: 5px !important;
    left: 12px !important;
}
